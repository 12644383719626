import '../estilos/relleno1.css';
const Relleno1=()=>{
    return(
        <div className='relleno grid '>
            <div className='titulogrande'> 
            <span> INOVACION </span> <br />  <span> EXPERIENCIA</span>  <br />  <span>IDENTIDAD  <span className='rojo'>.</span> </span>
            </div>
        
            <div className='ppp hidden md:block'><span className='parrafrelle1'>Somos un grupo de jóvenes profesionales en el área de la ingeniería en informática y diseño gráfico dedicados al desarrollo de soluciones innovad</span></div>
        </div>
        
    )
}
export default Relleno1;