import '../estilos/contacto.css';
import * as React from 'react';
import TextField from '@mui/material/TextField';

const Contacto=()=>{
    return(
        <div className='contacto'>
        <div className='cuerpocontacto grid md:grid-cols-2'>
            <div className='titulocont col-span-2'>          
                <h1 className='titulogrand flex'>SIGAMOS EN</h1>
                <h1 className='titulogrand' style={{color:"#860000"}}>CONTACTO </h1></div>
            <div className='mitadcontacto hidden md:block'>
  
            <p style={{color:"#B30000", width:"70%"}}>A continuación, ponemos a su disposición los canales por los cuales nos podemos comunicar, y si requiere empezar un proyecto con nosotros. Las redes sociales trataremos de tenerlas actualizadas y subiendo información constantemente. El teléfono si no es contestado puede enviar un mensaje por WhatsApp y nos pondremos en contacto a la brevedad posible. </p>
            <div className='cuadrocont grid grid-cols-6'>
                <div className='prueba1 col-span-1'><div className='circulorojo'>1</div></div>
                <div className='prueba col-span-5'><p>Ingrese sus datos para que nos pongamos en contacto.</p></div>
                
                <div className='fondolinea col-span-1'>&nbsp;</div>
                <div className='prueba col-span-5'></div>


                <div className='prueba2 col-span-1'><div className='circulorojo' style={{color:"red"}}>2</div></div>
                <div className='prueba col-span-5'><p style={{color:"#B80000"}}>Describa lo mas detallado posible su requerimiento.</p></div>
                <div className='pt-6 col-span-6'>Lo contactaremos lo antes posible para empezar a trabajar</div>
            </div>
            </div>

            <div className='mitadcontact'>
            <div className='hidden xs:block'>
                <h1 className='titulogrand'>SIGAMOS EN</h1>
                <h1 className='titulogrand' style={{color:"#860000"}}>CONTACTO </h1>
            </div>

                <h1 style={{maxWidth:"400px",color:"#B30000"}} >Empecemos una conversa cotidiana para poder acercar  nuestros servicios a su empresa. Lo principal es que podamos coordinar un dia y hora para poder hablar de algun proyecto que le interese. </h1>
                <div className='formcont flex flex-col'>
                        <TextField
                        id="outlined-uncontrolled"
                        label="NOMBRE"
                        variant="standard"
                        color="error"
                        defaultValue=""
                        /> 
                        <TextField
                        id="outlined-uncontrolled"
                        label="NOMBRE"
                        variant="standard"
                        color="error"
                        defaultValue=""
                        /> 
                    <div className='inputcont'>                    

                    </div>


                    <div><button>
                         <div className='btncont mt-5'><span >Siguiente</span></div>
                    </button>
                       
                    </div>
                    
                </div>
            </div>


           
            </div> 
        
        <div className='footer'>lalalal</div>

        </div>

    )
} 
export default Contacto;