import '../estilos/diseno.css';
import linea from '../imagenes/linea.svg';
import flecha from '../imagenes/flecha.svg';
import flecha2 from '../imagenes/flecha2.svg';
import imagen1 from '../imagenes/3d1.png';
import circulobase from '../imagenes/circulobase.svg';
const Diseno=()=>{
    return(
        <div className="diseno grid grid-cols-12">
            <div className='navbardiseno col-span-0.5'><img className='linea' src={linea} alt="my logo"/>DISEÑO<img className='linea' src={linea} alt="my logo"/></div>
            <div className='contenido col-span-11'>
                
                
                {/* ESTE DIV SE PASA DE LOS MARGENES */}

                <div className='titulod'>
                    
                    <h1 className='titulogrand'>DISEÑO</h1>
                
                
                
                </div>

                 

                
                <div className='infod grid xs:grid-cols-1  md:grid-cols-2'>
                    <div className='hidden md:block'>
                        <p className='parradise'>No es fácil encontrar quién logrará crear una identidad de marca que te identifique, te guste, y que represente todo lo que tu proyecto es para tí. Juntando toda nuestra experiencia con nuestras diseñadoras de primer nivel creamos este producto que es justo lo que necesitas. 
                        <br /><br />
                        La identidad visual es la elaboración de diversos elementos gráficos y visuales, que expresan la esencia de una marca. Algunos de los elementos principales que conforman el desarrollo de una identidad visual son el logo, la tipografía y su paleta de colores. 
                        <br /><br />
                        Tener una identidad de marca potente y clara, es fundamental para lograr diferenciarte de tu competencia y lograr el éxito con tu emprendimiento. Después de todo, ¿cómo vas ser reconocido sin tener una imagen propia</p>
                    </div>
                   
                   
                    <div> 
                        <div className='titulo3d'><h1 >Titulo</h1></div>
                        <div className='modelo3d '>
                          
                           <img className='imagen3d' src={imagen1} alt="my logo"/> 
                            
                        
                        </div>
                        <div className='centrado'>
                         <img className='circulobase' src={circulobase} alt="my logo"/>
                        </div>
                        <div className='menum2'>
                                <div><button className='circulobtn btn1'> </button></div><div><button className='circulobtn btn2'> </button></div><div   > <button className='circulobtn btn3' > </button></div>

                </div>  
                    </div>
                </div>
             
               
            </div>
            
        </div>
    )
}
export default Diseno;