import '../estilos/bienvenida.css';
import logo from '../imagenes/logo.svg';

const Bienvenida=()=>{
    return(
        <div className="bienvenida h-full w-full">
            <div className='barbienvenida'>barrra</div>
            <div className='grid grid-cols-6'>
                <div className='col-span-1'></div>
                <div className='cuerpobienvenida col-span-4'><img className='logo' src={logo} alt="my logo"/></div>
                <div className='col-span-1'></div>
            </div>
            <div className='grid grid-cols-2'>
                <div className='fotbienvenida col-span-1'><span className='spanbienve'><h1 className='pruebg'>DISEÑO & <span className='rojo'>DESARROLLO </span></h1> <p className='parrafbienve'>Enfocados en desarrollar soluciones eficientes y de vanguardia que puedan potenciar su negocio</p></span></div>
                <div className='col-span-1'>2</div>
            </div>
        </div>
    )
} 
export default Bienvenida;