import '../estilos/relleno2.css';
import logo from '../imagenes/logo.svg';

const Relleno2=()=>{
    return(
        <div className='relleno2 grid md:grid-cols-2'>
        <div className='mitadfutu'>
            <h1 className='parrafo2'>Algunos de nuestros desarrollos</h1>
            <h1 className='titulogrand2'>DESARROLLOS DE HOY</h1>
            <h1 className='titulogrand2' style={{color:"red"}}>PARA EL FUTURO </h1>
            <p className='pt-5 parrafo2'>Estamos en constante estudios de nuevas y mejores tecnologías, por que sabemos que el área de la Informática avanza muy rápido y nosotros vamos avanzando con ella. Por esto estamos constantemente empleando nuevas técnicas y nuevas tecnologías en nuestras soluciones.</p>
        </div>
            <div className='mitadfuturo hidden md:block'></div>
        </div>

    )
} 
export default Relleno2;