import '../estilos/servicios.css';
import iconquien from '../imagenes/iconquien.svg';
import flecha from '../imagenes/flecha.svg';
import flecha2 from '../imagenes/flecha2.svg';
import iconfigma from '../imagenes/iconfigma.svg';
import iconboot from '../imagenes/iconboot.svg';
import cara from '../imagenes/CARAROJA.png';
import linea from '../imagenes/linea.svg';
const Servicios=()=>{
    return(
        <div className='servicios grid grid-cols-12'>
            <div className='navbar col-span-0.5'><img className='linea' src={linea} alt="my logo"/>PRESENTACION<img className='linea' src={linea} alt="my logo"/></div>

            <div className='circuloserv hidden md:block col-span-6 '>
                <div className='contcirc'><img className='cara' src={cara} alt="my logo"/></div>
            </div>
            <div className='cuerposerv'>
                <div className=' col-span-5 '>
                    <h1 className='antitulo'><img className='iconquien' src={iconquien} alt="my logo"/></h1>
                    <span className='titulogranserv'>QUIEN SOY </span>
                    <p className='parrafserv'>Ingeniero en informática con 8 años de experiencia laboral en el rubro. Trabajador con alto nivel de compromiso y motivación por desarrollarse en el área de Informática desde el trabajo práctico hasta cargos de mayor responsabilidad. <span className='hidden md:block'>Enfocado en el desarrollo de buenas prácticas, perseverante, prolijo y metódico, orientado al cumplimiento de objetivos y en el buen clima laboral. En la búsqueda de estabilidad y adquisición de mayores competencias como trabajador en el área de la Informática.</span> </p>
                    <h1 className='subtblanco pt-3 pb-3'>Mi Formacion</h1>
                    <span className='lineahori'></span>
                    <span className='numerocv'>01</span> <span className='formacion'>Ingeniero Informatico - Duoc UC</span>
                    <span className='lineahori'></span>
                    <span className='numerocv'>02</span> <span className='formacion'>Técnico en administración de redes - Inacap</span>
                    <span className='lineahori'></span>
                    <span className='numerocv'>03</span> <span className='formacion'>Habilidades para la empleabilidad - AngloAmerican</span>
                    <span className='lineahori'></span>

                    <div className='iconoscua'>
                        <span className='cuadroicono mt-5'><img className='iconfigma' w src={iconfigma} alt="my logo"/></span>
                        <span className='cuadroicono2 mt-5'><img className='iconboot' src={iconboot} alt="my logo"/></span>

                    </div>
                    
                    <div className='pruebaser mg-5 mb-3'>    <span className='franjanegra mt-5'>FIGMA   3DMAX   TAILWIND</span></div>
                 <div className='menum2'>
                                <div><button className='circulobtn btn1'> </button></div><div><button className='circulobtn btn2'> </button></div><div   > <button className='circulobtn btn3' > </button></div>

                </div>         
            

                </div>
                

            
    
            </div>
        
        
        </div>
    )
}
export default Servicios;