import React from 'react';
import './index.css';
import App from './App';
import './estilos/estructura.css';
import './fonts/roboto/Roboto-Bold.ttf';
import Relleno1 from './componentes/Relleno1';
import Servicios from './componentes/Servicios';
import Estructura from './componentes/Estructura';
import Inicio from './componentes/Inicio';
import Portafolio from './componentes/Portafolio';
import Diseno from './componentes/Diseno';
import Relleno2 from './componentes/Relleno2';
import Contacto from './componentes/Contacto';
import Bienvenida from './componentes/Bienvenida';
import * as ReactDOM from 'react-dom/client';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Bienvenida/>
    <Inicio/>
    <Relleno1/>
    <Servicios/>
    <Diseno/>
    <Portafolio/>
    <Relleno2/>
    <Contacto/>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
