import '../estilos/portafolio.css';
import logo from '../imagenes/logo.svg';

const Portafolio=()=>{
    return(
        <div className='portafolio'>
            <h1>PORTAFOLIO</h1>
        </div>
    )
} 
export default Portafolio;