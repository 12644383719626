import '../estilos/inicio.css';
import { gsap } from "gsap";
import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import flecha from '../imagenes/flecha.svg';
import flecha2 from '../imagenes/flecha2.svg';
import linea from '../imagenes/linea.svg';
import foto from '../imagenes/foto.png';
const Inicio=()=>{

    let mov=0;
    let num=0;
    const [empresa,setEmpresa] = useState("GYC");
    const [desempresa,setDescempresa] = useState("Desempeñando funciones de soporte y desarrollo en el departamento de infomatica , mejoramiento u migración de un sistema de conde de HH y reservas desarrollado en PHP y MYSQL , desarrollo nuevo sitio web para la empresa , soporte remoto para personal de otras sucursales , administración de base de datos y servidores de la compañía ");
    const [skillsempresa,setSkillempresa] = useState("GYC");
    
    function empresa1() {
        gsap.to(".titulocv",{opacity:0,duration:0});
        gsap.to(".subtitulocv",{opacity:0,duration:0});
        gsap.to(".parrafocv",{opacity:0,duration:0});
        setEmpresa("Isercon");
        gsap.to(".titulocv",{opacity:1,duration:1});
        gsap.to(".subtitulocv",{opacity:1,duration:2});
        gsap.to(".parrafocv",{opacity:1,duration:3});
        setDescempresa("Inicio de practica de ingeniería en informática desarrollando una mejora y migración de un sistema de control de HH y reservas en php y mysql desde los servidores de la empresa a un sistema web, a cargo de sol servidores de la empresa tanto en sistemas Windows como en sistemas Linux , soporte remoto a las distintas sucursales de la empresa y participando activamente en el desarrollo de nuevas soluciones para distintos clientes de la empresa.");
        gsap.to('.uno',{fontSize:10+'vw',color:'white'})
        gsap.to('.btn1',{width:60+'px',backgroundColor:'#7C0000'})
        gsap.to('.btn2',{width:20+'px',backgroundColor:'#DE0000'})
        gsap.to('.btn3',{width:20+'px',backgroundColor:'#DE0000'})
        gsap.to('.dos',{fontSize:5+'vw',color:'#7C0000'})
        gsap.to('.tres',{fontSize:5+'vw',color:'#7C0000'})
      }

      function empresa2() {
        setEmpresa("GYC");
        gsap.to(".titulocv",{opacity:0,duration:0});
        gsap.to(".subtitulocv",{opacity:0,duration:0});
        gsap.to(".parrafocv",{opacity:0,duration:0});
        setDescempresa("A cargo del sistema ERP Manager de la empresa , desarrollando estrategias de logística a través de sistemas de información que permitieran dar una pronta y eficiente respuesta ante requerimientos de insumos en la minería , soporte a los usuarios de la compañía , instalación de antenas satelitales en instalaciones de minería. Programación de aplicaciones visual Basic SQL y JavaScript.");
        gsap.to(".titulocv",{opacity:1,duration:1});
        gsap.to(".subtitulocv",{opacity:1,duration:2});
        gsap.to(".parrafocv",{opacity:1,duration:3});
        gsap.to('.dos',{fontSize:10+'vw',color:'white'})
        gsap.to('.btn2',{width:60+'px',backgroundColor:'#7C0000'})
        gsap.to('.btn1',{width:20+'px',backgroundColor:'#DE0000'})
        gsap.to('.btn3',{width:20+'px',backgroundColor:'#DE0000'})
        gsap.to('.tres',{fontSize:5+'vw',color:'#7C0000'})
        gsap.to('.uno',{fontSize:5+'vw',color:'#7C0000'})
      }
      function empresa3() {
        setEmpresa("Sakata");
        gsap.to(".titulocv",{opacity:0,duration:0});
        gsap.to(".subtitulocv",{opacity:0,duration:0});
        gsap.to(".parrafocv",{opacity:0,duration:0});
        setDescempresa("Programación en C# y SQL sobre los servidores locales de la empresa, aplicaciones de mensajería, bitácora, aplicaciones de control de activos, etc. Generando una mejora continua en base a nuevas tecnologías, instalación de antenas satelitales e infractuctura de red en otras sucursales de la empresa. Dar soporte a los empleados de la empresa.");
        gsap.to(".titulocv",{opacity:1,duration:1});
        gsap.to(".subtitulocv",{opacity:1,duration:2});
        gsap.to(".parrafocv",{opacity:1,duration:3});
        gsap.to('.tres',{fontSize:10+'vw',color:'white'})
        gsap.to('.btn3',{width:60+'px',backgroundColor:'#7C0000'})
        gsap.to('.btn2',{width:20+'px',backgroundColor:'#DE0000'})
        gsap.to('.btn1',{width:20+'px',backgroundColor:'#DE0000'})
        gsap.to('.dos',{fontSize:5+'vw',color:'#7C0000'})
        gsap.to('.uno',{fontSize:5+'vw',color:'#7C0000'})
      }





    return(
        <div className='inicio h-full w-full grid grid-cols-12'>
            <div className='navbar col-span-0.5'><img className='linea' src={linea} alt="my logo"/>PRESENTACION<img className='linea' src={linea} alt="my logo"/></div>
            <div className='iniciologo col-span-11'>
                <div className='bar'>
                <div className='tituinicio'><span> <h1 className='gerpue'>Gerardo Puebla</h1><h1 className='subti'>Ingeniero</h1><h1 className='subti'>Informatico</h1></span></div></div>
                <div className='numeros flex justify-between items-end'>
                        <div className='uno'>17 </div> 
                        <div className='dos'> 23 </div>
                        <div className='tres'>24 </div>
                       
                      
                </div>
                
                    <div className='grid xs:grid-cols-1  md:grid-cols-2'>                    
                    <div className='mitadcv hidden md:block'>
                        <div className='controles'>
                           
                        </div>
                    </div>
                    <div >
                        <div className='contenidoc grid grid-cols-1'>
                        <div className='contenidocv'><h1 className='titulocv'>{empresa}</h1> <h2 className='subtitulocv'><strong>INGENIERO INFORMATICO</strong></h2><p className='parrafocv md:w-60'> {desempresa}</p><h1 className='textshow'>PHP - MYSQL - JAVA</h1></div>
                        </div>

                    <div className='menum'>
                        <div><button className='circulobtn btn1' onClick={empresa1}> </button></div><div><button className='circulobtnact btn2' onClick={empresa2}> </button></div><div   > <button className='circulobtn btn3' onClick={empresa3}> </button></div>
                    </div>

                    </div> 

                    </div>
                   

                    </div>
           
        </div>
    );
}
export default Inicio;